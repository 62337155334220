import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    blue: {
      900: "#000044",
      800: "#0F0D9F",
      700: "#0303a2",
      600: "#1968D8",
      500: "#5da6e3",
      400: "#3BD4F8",
      300: "#56FBFB",
      200: "#73FEE5",
      100: "#90FFD7",
      50: "#AFFFD3",
    },
    purple: {
      900: "#281328",
      800: "#2C1832",
      700: "#2E1E3C",
      600: "#2F2345",
      500: "#2e294e",
      400: "#454766",
      300: "#61687E",
      200: "#7E8896",
      100: "#9AA6AE",
      50: "#B7C2C5",
    },
    brown: {
      "50": "#F4F4F0",
      "100": "#E1DFD6",
      "200": "#CDCBBC",
      "300": "#B9B7A1",
      "400": "#A6A287",
      "500": "#928E6D",
      "600": "#757257",
      "700": "#585541",
      "800": "#3B392B",
      "900": "#1D1C16",
    },
    green: {
      "50": "#EEF6F1",
      "100": "#CFE7D6",
      "200": "#B1D8BC",
      "300": "#92C9A2",
      "400": "#74B988",
      "500": "#55AA6E",
      "600": "#448858",
      "700": "#336642",
      "800": "#22442C",
      "900": "#112216",
    },
    red: {
      "900": "#FF0000",
    },
  },
  styles: {
    global: {
      body: {
        bg: "blue.500",
        color: "blue.900",
      },
    },
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  components: {
    Heading: {
      baseStyle: {
        paddingBottom: "2",
      },
    },
    Text: {
      baseStyle: {
        paddingBottom: "1",
        paddingTop: "1",
      },
    },
    Link: {
      variants: {
        active: {
          color: "blue.900",
        },
      },
    },
  },
});
