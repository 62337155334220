import { Box, Button, Text, Heading, Flex, Container } from "@chakra-ui/react";
import { useEffect } from "react";
import { useCookieConsent } from "use-cookie-consent";
import { grantConsent } from "../../../lib/ga";
import Link from "../Link";
import { useCookies } from "react-cookie";
import { addYears } from "date-fns";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const { consent, acceptAllCookies, declineAllCookies } = useCookieConsent();
  const dateInTwoYears = addYears(new Date(), 2);

  if (typeof window === "undefined") {
    return null;
  }

  useEffect(() => {
    if (consent) {
      grantConsent();
    }
  }, [consent]);

  if (cookies.cookieConsent) {
    return null;
  }

  return (
    <Flex
      paddingY={6}
      paddingX={4}
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      bg="gray.200"
      alignItems="center"
      flexWrap="wrap"
      zIndex={2}
    >
      <Container maxWidth="container.xl">
        <Box>
          <Heading as="h5" size="sm">
            Your privacy is important to us.
          </Heading>
          <Text>
            We use cookies for analytics purposes only. See our{" "}
            <Link href="/privacy">Privacy Policy</Link>
          </Text>
        </Box>

        <Box marginLeft="auto">
          <Button
            color="white"
            bg="blue.900"
            _hover={{
              backgroundColor: "blue.700",
            }}
            marginRight={2}
            onClick={() => {
              acceptAllCookies();
              setCookie("cookieConsent", true, {
                expires: dateInTwoYears,
              });
            }}
          >
            Accept
          </Button>

          <Button
            onClick={() => {
              declineAllCookies();
              setCookie("cookieConsent", false, {
                expires: dateInTwoYears,
              });
            }}
          >
            Reject
          </Button>
        </Box>
      </Container>
    </Flex>
  );
};

export default CookieConsent;
