import { Toaster } from "react-hot-toast";
import type { AppProps } from "next/app";
import { Provider } from "urql";
import { client } from "../client/graphql/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "../theme";
import "@fontsource/montserrat"; // importing of font family
import { useRouter } from "next/router";
import { useEffect } from "react";
import { pageview } from "../lib/ga";
import CookieConsent from "../client/components/CookieConsent";
import Head from "next/head";
import ogImage from "../../public/social.png";
import { LinkedInInsightTag } from "nextjs-linkedin-insight-tag";

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const domain = "https://www.refcheka.com";
  const canonicalURL = domain + router.pathname;
  const serverType = process.env.SERVER_TYPE || "local";

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <Provider value={client}>
      <ChakraProvider theme={theme} resetCSS={true}>
        <Head>
          <link rel="canonical" href={canonicalURL} />
          <meta property="og:url" content={canonicalURL} />
          <meta property="og:image" content={`${domain}${ogImage}`} />
          <meta property="og:image:alt" content={"Refcheka"} />
          <meta property="og:image:width" content={"1200"} />
          <meta property="og:image:height" content={"630"} />
        </Head>
        {(() => {
          if (serverType == "production") {
            return <LinkedInInsightTag />;
          }
        })()}
        <Component {...pageProps} />
        <Toaster />
        <CookieConsent />
      </ChakraProvider>
    </Provider>
  );
}

export default CustomApp;
